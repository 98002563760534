import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/global/layout';
import Container from '../components/global/container';
import Header from '../components/global/header';
import MCover from '../static/mind-mate-cover.png';
import TCover from '../static/animation/tcs-poster.png';
import LCover from '../static/animation/lebc-poster.png';
import MMVideo from '../static/animation/Mind-Mate.mp4';
import Tcs from '../static/animation/Technically-Perfect-Pizza-1.mp4';
import Lebc from '../static/animation/LEBC-Hummingbird.mp4';
import PlayButton from '../static/play--button.svg';

import ShCover from '../static/shar-cover.png';
import Shar from '../static/EWY_Shar Simpson.mp4';

import WonzCover from '../static/wonz-poster.jpg';
import WonzVideo from '../static/wonz.mp4';

import EwyCover from '../static/animation/animation-ewy-cover.png';
import EwyVideo from '../static/animation/animation-ewy.mp4';

import OssCover from '../static/animation/animation-oss-cover.png';
import OssVideo from '../static/animation/animation-oss.mp4';

import AvivaCover from '../static/animation/animation-aviva-cover.png';
import AvivaVideo from '../static/animation/animation-aviva.mp4';

const AnimationPage = (props) => {
    const { data, errors } = props;

    const mmVideo = useRef();
    const playButtonB = useRef();

    const [isPlayingMM, setIsPlayingMM] = useState(false);

    const handlePlayMM = e => {
        if(!isPlayingMM) {
            setIsPlayingMM(true);
            mmVideo.current.play();
        } else {
            setIsPlayingMM(false);
            mmVideo.current.pause();
        }
    }

    const TcsVideo = useRef();
    const playButtonN = useRef();

    const [isPlayingT, setIsPlayingT] = useState(false);

    const handlePlayT = e => {
        if(!isPlayingT) {
            setIsPlayingT(true);
            TcsVideo.current.play();
        } else {
            setIsPlayingT(false);
            TcsVideo.current.pause();
        }
    }

    const lebcVideo = useRef();
    const playButtonW = useRef();

    const [isPlayingL, setIsPlayingL] = useState(false);

    const handlePlayL = e => {
        if(!isPlayingL) {
            setIsPlayingL(true);
            lebcVideo.current.play();
        } else {
            setIsPlayingL(false);
            lebcVideo.current.pause();
        }
    }

    const sharVideo = useRef();
    const playButtonSh = useRef();

    const [isPlayingSh, setIsPlayingSh] = useState(false);

    const handlePlaySh = e => {
        if(!isPlayingSh) {
            setIsPlayingSh(true);
            sharVideo.current.play();
        } else {
            setIsPlayingSh(false);
            sharVideo.current.pause();
        }
    }

    const wonzVideo = useRef();
    const playButtonWonz = useRef();

    const [isPlayingWonz, setIsPlayingWonz] = useState(false);

    const handlePlayWonz = e => {
        if(!isPlayingWonz) {
            setIsPlayingWonz(true);
            wonzVideo.current.play();
        } else {
            setIsPlayingWonz(false);
            wonzVideo.current.pause();
        }
    }

    const ewyVideo = useRef();
    const playButtonEwy = useRef();

    const [isPlayingEwy, setIsPlayingEwy] = useState(false);

    const handlePlayEwy = e => {
        if(!isPlayingEwy) {
            setIsPlayingEwy(true);
            ewyVideo.current.play();
        } else {
            setIsPlayingEwy(false);
            ewyVideo.current.pause();
        }
    }

    const avivaVideo = useRef();
    const playButtonAviva = useRef();

    const [isPlayingAviva, setIsPlayingAviva] = useState(false);

    const handlePlayAviva = e => {
        if(!isPlayingAviva) {
            setIsPlayingAviva(true);
            avivaVideo.current.play();
        } else {
            setIsPlayingAviva(false);
            avivaVideo.current.pause();
        }
    }

    const ossVideo = useRef();
    const playButtonOss = useRef();

    const [isPlayingOss, setIsPlayingOss] = useState(false);

    const handlePlayOss = e => {
        if(!isPlayingOss) {
            setIsPlayingOss(true);
            ossVideo.current.play();
        } else {
            setIsPlayingOss(false);
            ossVideo.current.pause();
        }
    }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  return (
    <>
        <Header headerStyle="clear" />
        <Layout>
        <Container>
            <div className='site-wide'>

            <div className='aviva--page-banner-image showreel social-examples' style={{ paddingBottom: `0 !important` }}>
                    <div className='video--wrapper'>
                        {isPlayingAviva ? null :
                            <div className='video--overlay' onClick={handlePlayAviva}>
                                <img src={PlayButton} ref={playButtonAviva} />
                            </div>
                        }
                        <video
                            ref={avivaVideo}
                            className="hero__media"
                            controls="false"
                            poster={AvivaCover}
                        >
                            <source src={AvivaVideo} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingOss ? null :
                            <div className='video--overlay' onClick={handlePlayOss}>
                                <img src={PlayButton} ref={playButtonOss} />
                            </div>
                        }
                        <video
                            ref={ossVideo}
                            className="hero__media"
                            controls="false"
                            poster={OssCover}
                        >
                            <source src={OssVideo} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingEwy ? null :
                            <div className='video--overlay' onClick={handlePlayEwy}>
                                <img src={PlayButton} ref={playButtonEwy} />
                            </div>
                        }
                        <video
                            ref={ewyVideo}
                            className="hero__media"
                            controls="false"
                            poster={EwyCover}
                        >
                            <source src={EwyVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className='aviva--page-banner-image showreel' id="shar">
                    <div className='video--wrapper'>
                        {isPlayingSh ? null :
                            <div className='video--overlay' onClick={handlePlaySh}>
                                <img src={PlayButton} ref={playButtonSh} />
                            </div>
                        }
                        <video
                            ref={sharVideo}
                            className="hero__media"
                            controls="false"
                            poster={ShCover}
                        >
                            <source src={Shar} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className='aviva--page-banner-image showreel' id="wonz">
                    <div className='video--wrapper'>
                        {isPlayingWonz ? null :
                            <div className='video--overlay' onClick={handlePlayWonz}>
                                <img src={PlayButton} ref={playButtonWonz} />
                            </div>
                        }
                        <video
                            ref={wonzVideo}
                            className="hero__media"
                            controls="false"
                            poster={WonzCover}
                        >
                            <source src={WonzVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className='aviva--page-banner-image showreel' id="bilaal">
                    <div className='video--wrapper'>
                        {isPlayingMM ? null :
                            <div className='video--overlay' onClick={handlePlayMM}>
                                <img src={PlayButton} ref={playButtonB} />
                            </div>
                        }
                        <video
                            ref={mmVideo}
                            className="hero__media"
                            controls="false"
                            poster={MCover}
                        >
                            <source src={MMVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className='aviva--page-banner-image showreel' id="kirsty">
                    <div className='video--wrapper'>
                        {isPlayingT ? null :
                            <div className='video--overlay' onClick={handlePlayT}>
                                <img src={PlayButton} ref={playButtonN} />
                            </div>
                        }
                        <video
                            ref={TcsVideo}
                            className="hero__media"
                            controls="false"
                            poster={TCover}
                        >
                            <source src={Tcs} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className='aviva--page-banner-image showreel' id="shibon">
                    <div className='video--wrapper'>
                        {isPlayingL ? null :
                            <div className='video--overlay' onClick={handlePlayL}>
                                <img src={PlayButton} ref={playButtonW} />
                            </div>
                        }
                        <video
                            ref={lebcVideo}
                            className="hero__media"
                            controls="false"
                            poster={LCover}
                        >
                            <source src={Lebc} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </Container>
        </Layout>
    </>
  )
};

AnimationPage.propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object
  };

export default AnimationPage;
